import { createTheme } from '@mui/material/styles';

const typography = {
    h1: { // 36pt Font Bold
        fontSize: '48pt',
        fontWeight: 700,
        color: 'var(--text-primary)',
        transition: '1s color',
        fontFeatureSettings: '"salt" on',
    },
    h2: { // 24pt Font Bold
        fontSize: '36pt',
        fontWeight: 700,
        color: 'var(--text-primary)',
        transition: '1s color',
        fontFeatureSettings: '"salt" on',
    },
    h3: { // 24pt Font Semibold
        fontSize: '24pt',
        fontWeight: 600,
        color: 'var(--text-primary)',
        transition: '1s color',
        fontFeatureSettings: '"salt" on',
    }, 
    h4: { // 20pt Font Bold
        fontSize: '20pt',
        color: 'var(--text-primary)',
        fontWeight: 600,
        transition: '1s color',
        fontFeatureSettings: '"salt" on',
    }, 
    h5: { // 14pt Font Bold
        fontSize: '14pt',
        fontWeight: 600,
        color: 'var(--text-primary)',
        transition: '1s color',
        fontFeatureSettings: '"salt" on',
    },
    h6: { // 14pt Font Bold
        fontSize: '14pt',
        textTransform: 'uppercase',
        fontWeight: 600,
        color: 'var(--secondary)',
        transition: '1s color',
        fontFeatureSettings: '"salt" on',
    },
    body1: { // 18pt Font Regular
        fontSize: '1.1rem',
        color: 'var(--text-secondary)',
        transition: '1s color',
        fontFeatureSettings: '"salt" on',
    },
    body2: { // 18pt Font Regular
        fontSize: '1rem',
        color: 'var(--text-secondary)',
        transition: '1s color',
        fontFeatureSettings: '"salt" on',
    },
    subtext: {
        fontSize: '1.0rem',
        fontWeight: '700',
        color: 'var(--medium-gray)',
        transition: '1s color',
        fontFeatureSettings: '"salt" on',
    },
    button: {
        textTransform: 'none'
    }
}

export const theme = createTheme({
    typography: typography,
    palette: {
        primary: {
            main: '#a71060',
            contrastText: '#000000',
        },
        secondary: {
            main: '#f6379b',
            contrastText: '#FFFFFF'
        },
        warning: {
            main: '#FFD660',
            contrastText: '#000000'
        },
    }
});