import * as React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import Action from 'components/Action';
import global from 'styles/global'
import FileOpenIcon from '@mui/icons-material/FileOpen';
import WorkIcon from '@mui/icons-material/Work';

export default function Career() {

    const openResume = () => {
        window.open('./resume.pdf', "_blank");
    };
    
	return (
        <Grid container sx={[global.vCenter, global.fullHeight, { width: {lg: '1100px'}}]}>
            <Grid container sx={{display: 'flex', flexDirection: {xs: 'column', lg: 'row'}, justifyContent: 'space-between', alignItems: {xs: 'flex-start', lg: 'flex-end'}}}>
                <Box sx={global.hCenter}>
                    <WorkIcon sx={{color: 'var(--text-primary)', fontSize: {xs: 42, md: 60}}} />
                    <Typography variant="h1" sx={{ml: 3, fontSize: {xs: '32pt', md: '48pt'}}}>
                        Career
                    </Typography>
                </Box>
                <Action 
                    text="View resume" 
                    onClick={openResume}
                    endIcon={<FileOpenIcon/>} 
                    sx={{mx: 10, display: {xs: 'none', lg: 'flex'}}}
                    />                
            </Grid>
            <Grid container sx={{mt: {xs: 1, lg: 3}}}>
                <Experience
                    company="Ryde"
                    title="Co-Founder & CEO"
                    date="Jan 2022 - Sept 2023"
                />
                <Experience
                    company="CheddrSuite"
                    title="Product Manager"
                    date="Jan 2023 - Sept 2023"
                />
                <Experience
                    company="Goldman Sachs"
                    title="Summer Analyst"
                    date="Jun - Aug 2021"
                />
                <Experience
                    company="Amazon"
                    title="RoboMaker SDE Intern"
                    date="Jun - Sept 2020"
                />
            </Grid>
            <Box sx={{width: '100%', mt: 7, mb: 5, display: {xs: 'block', lg: 'none'}}}>
                <Action 
                    text="View resume" 
                    onClick={openResume}
                    endIcon={<FileOpenIcon/>} 
                    />  
            </Box>
        </Grid>
	);
};

function Experience ({ company, title, date }) {
    return (
        <Grid item xs={12} sm={6} lg={3} sx={styles.experience}>
            <Typography variant="h4" sx={{ mb: 0.25 }}>
                {company}
            </Typography>
            <Typography variant="body1" sx={{color: 'var(--text-primary)', mb: 1, fontWeight: 500}}>
                {title}
            </Typography>
            <Typography variant="body1">
                {date}
            </Typography>
        </Grid>
    )
}

const styles = {
	experience: {
        marginTop: 4,
        paddingLeft: 2,
        paddingRight: 6,
        borderLeft: '1px solid var(--secondary)',
        borderLeftColor: 'var(--secondary)',
	}
}