import * as React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import Action from 'components/Action';
import EmailIcon from '@mui/icons-material/Email';

export default function Contact() {
	return (
        <Box>
            <Grid container sx={[{mb: 25, position: 'relative'}]}>
                <Grid item xs={12} md={9}>
                    <Typography variant="h6" sx={{mb: 1}}>
                        Let's Work Together 
                    </Typography>
                    <Typography variant="h2" sx={{mb: 5, fontSize: {xs: '26pt', md: '36pt'}}}>
                        Want to Reach Out?
                    </Typography>
                    <Typography variant="body1" sx={{mb: 1}}>
                        If you'd like to talk about an opportunity, collaboration, or just want to say hi, feel free to send me a message below. 
                    </Typography>
                    <Typography variant="body1" sx={{mb: {xs: 5, md: 3}}}>
                        Also, entrepreneurship is life, so if you have any questions about starting a business, or advice on whether it's for you (it is), I'd be happy to chat!
                    </Typography>
                    <Action 
                        text="Send me an email" 
                        endIcon={<EmailIcon/>}
                        sx={{zIndex: 5}}
                        onClick={() => window.open('mailto:contact@emilygavrilenko.com', '_blank')}
                    />
                </Grid>
            </Grid>
            <Typography variant="body1" sx={{mb: 3, mt: 5}}>
                Coded by me :)
            </Typography>
        </Box>
	);
};