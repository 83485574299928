import * as React from 'react';
import { Grid, Box, Typography } from '@mui/material';
import Action from 'components/Action';
import global from 'styles/global'
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export default function About() {

    const AboutContent = () => {
        return (
            <Grid container>
                <Grid item xs={12} md={5}>
                    <Typography variant="h6" sx={{mb: 1}}>
                        About Me
                    </Typography>
                    <Typography variant="h2" sx={{mb: 5, fontSize: {xs: '26pt', md: '30pt'}}}>
                        Learning to Create & Creating to Learn
                    </Typography>
                    <Action 
                        text="Let's connect!" 
                        onClick={() => window.open('https://www.linkedin.com/in/emily-gavrilenko/', '_blank')}
                        endIcon={<LinkedInIcon/>}
                        sx={{mb: {xs: 3, md: 0}}}
                        />
                </Grid>
                <Grid item xs={12} md={7} sx={{paddingLeft: {xs: 0, md: 10}}}>
                    <Typography variant="body2" mb={2}>
                        Looks like you've stumbled onto my website, welcome! 
                    </Typography>
                    <Typography variant="body2" mb={2}>
                        My name is Emily Gavrilenko and I'm a Cal Poly SLO alumni, graduating with my BS and MS in Computer Science. I love to code and build things, starting off with Legos at a young age, advancing to robotics in high school, and transitioning to web development in college. Now, I'm venturing into the entrepreneurial world!
                    </Typography>
                    <Typography variant="body2" mb={2}>
                        As the former Co-Founder and CEO of Ryde, I worked with my team to revolutionize the long-distance student travel experience. We created a world where sharing a ride was simple, and students could travel wherever they wanted, whenever they wanted, with people they could trust. This experience ignited my love for entrepreneurship, check out what we built at <a href="https://www.rydecarpool.com" target="_blank" style={{color: "white"}}>rydecarpool.com</a>! Now, I'm seeking a new opportunity to leverage my technical, creative, and operational skills.
                    </Typography>
                    <Typography variant="body2">
                        In my free time (when it exists) I love to travel and try new things! From racing in an IronMan 70.3, to serving as the President of the CP Astronomy Club, competing on the Ballroom Dance team, and volunteering in my community through the Society of Women Engineers, I love to keep busy and meet new people along the way.
                    </Typography>
                </Grid>
            </Grid>
        )
    }

	return (
        <Box id='about' sx={{pt: 1}}>
            {/* Desktop */}
            <Grid container sx={[global.hCenter, styles.container]}>
                <AboutContent />
            </Grid>
            {/* Mobile */}
            <Box sx={{display: {xs: 'flex', md: 'none'}}}>
                <AboutContent />
            </Box>
        </Box>
	);
};

const styles = {
	container: {
        mt: 10, 
        border: '1px solid pink',
        borderColor: 'var(--secondary)',
        borderRadius: 5,
        py: 8,
        px: 10,
        alignItems: 'flex-start',
        display: {xs: 'none', md: 'flex'}
	}
}