import { Button, Typography } from '@mui/material';
import { styled } from '@mui/system';

// Define a new styled component with your custom styles
const StyledButton = styled(Button)(({ theme }) => ({
    color: 'white',
    padding: 0,
    paddingBottom: 5,
    paddingRight: 10,
    borderRadius: 0,
    borderBottom: '1px solid var(--secondary)',
    borderBottomColor: 'var(--secondary)',
    transition: 'all 0.3s ease',
    '&:hover': {
        textDecorationColor: 'pink',
        transform: 'scale(1.05)',
    },
    '&:active': {
        transform: 'scale(0.95)',
    },
}));

function MyButton({ text, onClick, endIcon, sx }) {
    return (
        <StyledButton endIcon={endIcon} sx={sx} onClick={onClick}>
            <Typography variant="h5">{ text }</Typography>
        </StyledButton>
    );
}

export default MyButton;