import * as React from 'react';
import { Container, Box, Typography, Grid } from '@mui/material';
import Action from 'components/Action';
import headshot from 'images/headshot.png';
import global from 'styles/global'

export default function Landing() {
	const scrollToAbout = () => {
		document.getElementById('about').scrollIntoView({behavior: 'smooth'});
	}

	return (
		<Grid container sx={[global.hCenter, global.fullHeight, {position: 'relative'}]}>
			<Grid item xs={12} md={6} sx={styles.text}>
				<Typography variant="h4" sx={{mb: 1}}>Hey it's me,</Typography>
				<Typography variant="h1" sx={{mb: 1}}>Emily</Typography>
				<Typography variant="body1" sx={{mb: {xs: -10, md: 5}}}>
					I'm a passionate and driven programmer and entrepreneur. 
					I graduated from Cal Poly with my BS/MS in Computer Science, served as the Co-Founder & CEO of Ryde, and now am eager for a new opportunity to leverage my expertise. 
				</Typography>
				<Action text="Scroll for more" onClick={scrollToAbout} sx={{display: {xs: 'none', md: 'flex'}}}/>
			</Grid>
			{/* Mobile Picture */}
			<Grid item xs={12} md={6} sx={{display: {xs: 'flex', md: 'none'}, justifyContent: 'center'}}>
				<img src={headshot} alt="Emily" style={{ height: 400, opacity: 0.9 }}/>
			</Grid>
			{/* Desktop Picture */}
			<Grid item xs={12} md={6} sx={{display: {xs: 'none', md: 'flex'}, paddingLeft: 5, height: 500}}>
				<img src={headshot} alt="Emily" style={{ height: 500, opacity: 0.9 }}/>
			</Grid>
		</Grid>
	);
};

const styles = {
	text: {
		width: '50vw',
		mt: {xs: 10, md: 0},
	}
}