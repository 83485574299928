import { Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from 'styles/theme';
import background from 'images/pink-background.png';
import background2 from 'images/pink-background2.png';
import mountains from 'images/mountains.webp';

// components
import Landing from 'pages/Landing';
import About from 'pages/About';
import Career from 'pages/Career';
import Contact from 'pages/Contact';


export default function App() {
	return (
		<ThemeProvider theme={theme}>
			<Box sx={{position: 'relative'}}>
				{/* Top background (Desktop) */}
				<Box sx={{position: 'absolute', top: 0, display: 'flex', width: '100%', justifyContent: 'flex-end', backgroundColor: 'var(--background-secondary)', display: {xs: 'none', md: 'flex'}}}>
					<img src={mountains} alt="background2" style={{opacity: 0.1, width: '100%'}}/>
				</Box>
				{/* Top background (Mobile) */}
				<Box sx={{position: 'absolute', top: 0, display: 'flex', width: '100%', justifyContent: 'flex-end', backgroundColor: 'var(--background-secondary)', display: {xs: 'flex', md: 'none'}}}>
					<img src={mountains} alt="background2" style={{opacity: 0.1}}/>
				</Box>
				{/* Content */}
				<Box sx={styles.root}>
					<Box maxWidth='lg'>
						<Landing />
						<About />
						<Career />
						<Contact />
					</Box>
				</Box>
				{/* Bottom background */}
				<Box sx={{position: 'absolute', bottom: 0, display: 'flex', width: '100%', justifyContent: 'flex-end', zIndex: 1}}>
					<img src={background} alt="background" style={{opacity: 0.5, width: '50%'}}/>
				</Box>
			</Box>
		</ThemeProvider>
	);
}

const styles = {
    root: {
		minHeight: '100vh',
		backgroundColor: 'var(--background)',
        display: 'flex',
        flexDirection: 'column',
		alignItems: 'center',
        px: {xs: '36px', md: '200px'},
		zIndex: 3,
    }
}